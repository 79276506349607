<template>
	<div class="about">
		<div class="about-banner center-noflex" :style='"background:url("+backgrond_img+")#0757dd center no-repeat;"'>
			<div class="banner-title">{{cate_title}}</div>
			<div>{{cate_content}}</div>
		</div>
		<div style="padding-bottom: 40px">
			<div class="center-noflex">
				<div class="about-title">关于我们</div>
				<div class="about-subtitle">About Us</div>
			</div>
			<div class="about-img">
				<img src="@/assets/about/About_Icon1.png" alt="" />
			</div>
			<div class="center-noflex">
				<div class="about-title">中心介绍</div>
				<div class="about-subtitle">Introduce</div>
			</div>
			<div class="about-img">
				<img src="@/assets/about/About_Icon2.png" alt="" />
			</div>
			<div class="center-noflex">
				<div class="about-title">平台优势</div>
				<div class="about-subtitle">Advantage</div>
			</div>
			<div class="about-img">
				<img src="@/assets/about/About_Icon3.png" alt="" />
			</div>
			<div class="center-noflex">
				<div class="about-title">交易角色</div>
				<div class="about-subtitle">Transaction role</div>
			</div>
			<div class="about-img">
				<img src="@/assets/about/About_Icon4.png" alt="" />
			</div>
			<div class="center-noflex">
				<div class="about-title">交易模式</div>
				<div class="about-subtitle">Transaction mode</div>
			</div>
			<div class="about-img">
				<img src="@/assets/about/About_Icon5.png" alt="" />
			</div>
			<div class="center-noflex">
				<div class="about-title">业务板块</div>
				<div class="about-subtitle">Business segment</div>
			</div>
			<div class="about-img">
				<img src="@/assets/about/About_Icon6.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "about",
		async created() {
			const {
				data
			} = await this.$api({
				c: "index",
				a: "about",
			});
			//console.log(data);
			this.cate_title = data.banner.title;
			this.cate_content = data.banner.content;
			this.backgrond_img = data.banner.image;
			//console.log(this.cate_content);

		},
		data() {
			return {
				backgrond_img: require("@/assets/about/about_banner.png"),
				cate_title: '关于中心',
				cate_content: '汇聚国内优质文化IP版权提供IP交易与授权',
			};
		},
	};
</script>

<style lang="scss" scoped>
	.about-title {
		font-size: 38px;
		color: #454545;
		margin-top: 40px;
		font-weight: 100;
	}

	.about-subtitle {
		margin-bottom: 35px;
		font-size: 14px;
		color: #454545;
		font-weight: 100;
	}

	.about-banner {
		// background: url("../../assets/about/about_banner.png") #0757dd center
		//   no-repeat;
		background-size: 100% 100%;
		height: 400px;
		line-height: 60px;
		color: #ffffff;

		.banner-title {
			font-weight: 900;
			font-size: 40px;
		}
	}

	.about-img {
		display: flex;
		justify-content: center;

		img {
			width:1200px;
			object-fit: cover;
		}
	}
</style>
